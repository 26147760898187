// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/client/hooks/login.ts"
);
import.meta.hot.lastModified = "1731095780044.3142";
}
// REMIX HMR END

import {
  useNavigate,
  useNavigation,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { z } from 'zod'

import { useAPI } from '~/client/api'
import { isResourceUrl } from '~/utils/network'

export function getLoginEndpoint() {
  return '/api/v1/login/session'
}

export const loginSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Please enter a valid email' }),
  password: z.string().min(1, { message: 'Password is required' }).min(5, {
    message: 'Password is at least 5 characters',
  }),
  slackId: z.string().optional().nullable(),
})

export function useLogin() {
  const nav = useNavigate()
  const [searchParams] = useSearchParams()
  const { revalidate } = useRevalidator()
  const api = useAPI()
  return useMutation({
    mutationKey: [getLoginEndpoint()],
    mutationFn: async (data: z.infer<typeof loginSchema>) => {
      const client = await api()
      const body = loginSchema.parse(data)
      await client.login.loginSession(
        { username: body.username, password: body.password },
        undefined,
        body.slackId,
      )
      return true
    },
    onSuccess: () => {
      revalidate()
      const redirectUrl = searchParams.get('redirect') ?? '/'
      nav(isResourceUrl(redirectUrl) ? '/' : redirectUrl)
    },
  })
}

/**
 * This hook should only be called if the app is determined to be in an iframe.
 */
export function useLoginWidget({
  widgetAccount,
}: {
  widgetAccount: string | null
}) {
  const nav = useNavigate()
  const { revalidate } = useRevalidator()
  const api = useAPI()
  return useMutation({
    mutationKey: [getLoginEndpoint()],
    mutationFn: async (data: z.infer<typeof loginSchema>) => {
      const client = await api()
      const body = loginSchema.parse(data)
      // Send partitioned=true to set a cross-origin and iframe compatible cookie
      if (!widgetAccount) throw new Error('widgetAccount is required')
      await client.login.loginSession(body, true)
      return true
    },
    onSuccess: () => {
      revalidate()
      nav(`/${widgetAccount}/chats`)
    },
  })
}

export function getFirebaseLoginEndpoint() {
  return 'api/v1/login/session/firebase'
}

export const tokenSchema = z.object({
  token: z.string(),
})

export function useLoginFirebase({
  widgetAccount,
}: {
  widgetAccount: string | null
}) {
  const nav = useNavigate()
  const { revalidate } = useRevalidator()
  const api = useAPI()
  return useMutation({
    mutationKey: [getFirebaseLoginEndpoint()],
    mutationFn: async ({ token }: z.infer<typeof tokenSchema>) => {
      const client = await api()
      if (!widgetAccount) throw new Error('widgetAccount is required')
      await client.login.loginSessionFirebase(token, widgetAccount)
      return true
    },
    onSuccess: () => {
      revalidate()
      nav(`/${widgetAccount}/chats`)
    },
  })
}

export function getLoginOAuthEndpoint() {
  return '/api/v1/login/session/oauth'
}

export function useLoginOAuth() {
  const navigation = useNavigation()
  const api = useAPI()
  const query = useQuery({
    queryKey: [getLoginOAuthEndpoint()],
    queryFn: async () => {
      const client = await api()
      return client.login.loginSessionOauth()
    },
    retry: false,
    enabled: false, // We will manually trigger this query with `refetch`
  })

  const { data, refetch, isPending, error } = query
  // Fetch the endpoint if there is not data
  useEffect(() => {
    if (data == null && navigation.state === 'idle') refetch()
  }, [data, refetch, navigation.state])

  // Redirect to the OAuth URL if the query is successful and not pending
  const shouldRedirect = data != null && !isPending && error == null
  useEffect(() => {
    if (shouldRedirect && typeof window !== 'undefined')
      window.location.href = data
  }, [data, shouldRedirect])

  return query
}
